import React from "react"
import PropTypes from "prop-types"

const handleClick = (props, e) => {
  if (typeof props.onClick === `function`) {
    props.onClick(e)
  }
  let redirect = true
  if (
    e.button !== 0 ||
    e.altKey ||
    e.ctrlKey ||
    e.metaKey ||
    e.shiftKey ||
    e.defaultPrevented
  ) {
    redirect = false
  }
  if (props.target && props.target.toLowerCase() !== `_self`) {
    redirect = false
  }
  if (window.analytics) {
    window.analytics.track(
      "Click",
      {
        category: `Outbound Link`,
        label: props.href,
      },
      null,
      () => {
        if (redirect) {
          document.location = props.href
        }
      }
    )
  } else {
    if (redirect) {
      document.location = props.href
    }
  }

  return false
}

function OutboundLink(props) {
  return (
    <a
      {...props}
      onClick={(e) => handleClick(props, e)}
      onKeyDown={(e) => handleClick(props, e)}
      role="button"
      tabIndex={0}
    >
      {props.children}
    </a>
  )
}

OutboundLink.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
}

export { OutboundLink }
