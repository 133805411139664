// import React from "react"

import { LinkCheck } from "../utils/linkCheck"

const ContentAst = {
  // h2: ({ children }) => (
  //   <h2 className="text-3xl leading-tight font-bold mb-6 mt-6 first:mt-0 md:text-4xl">
  //     {children}
  //   </h2>
  // ),
  // h3: ({ children }) => (
  //   <h3 className="text-2xl leading-tight font-bold mb-6 mt-6">{children}</h3>
  // ),
  // p: ({ children }) => (
  //   <p className="font-serif text-xl text-left mb-4 break-words">{children}</p>
  // ),
  a: LinkCheck,
  // ul: ({ children }) => (
  //   <ul className="list-disc list-inside text-xl">{children}</ul>
  // ),
  // ol: ({ children }) => (
  //   <ul className="list-decimal list-inside text-xl">{children}</ul>
  // ),
  // blockquote: ({ children }) => (
  //   <blockquote className="text-xl text-gray-700 border-l-4 pl-4 border-gray-200">
  //     {children}
  //   </blockquote>
  // ),
}

export default ContentAst
