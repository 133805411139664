import React from "react"
import { Link } from "gatsby"

import { urlRegex } from "../../utils/urlRegex"
import { OutboundLink } from "./outboundLink"

export const LinkCheck = (props) => (
  <>
    {urlRegex(props.href) ? (
      <OutboundLink
        // className="underline hover:opacity-75"
        href={props.href}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        {props.children}
      </OutboundLink>
    ) : (
      <Link
        // className="underline hover:opacity-75"
        to={`/${props.href}/`}
      >
        {props.children}
      </Link>
    )}
  </>
)
